<template>
  <div :class="['poi-external', selectedpoi.attributes.field_poi_categoria]">
  
  <LeftTopButton @close-button="closePOI" />
 
  <div class="poi-content">
    <div class="poi-title">{{ selectedpoi.attributes.title }}</div>
    <div class="poi-subtitle">{{ selectedpoi.attributes.field_poi_sottotitolo }}</div>
    <div v-if="selectedpoi.attributes.field_poi_categoria == 'Azienda'">
      <VueSlickCarousel v-bind="carouselSettings" v-if="carimages.length>0">
        <!--<div v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index">
          <img :src="imgsrc(image)" alt="">
        </div>-->
        <Images v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index"
          :breakstyles="{'0': 'sm_image'}" 
          :imagedata="selectedpoi" fieldname="field_poi_immagini" 
          :resultindex="index" />
      </VueSlickCarousel>



      <div class="poi-descrizione" v-if="selectedpoi.attributes.field_poi_descizione"
        v-html="selectedpoi.attributes.field_poi_descizione.value">
      </div>


      <div class="poi-label-contatti" v-if="hasContatti()">{{ this.$store.state.labelData[this.lang].contatti.contatti }}</div>
      <div class="poi-contatti" v-if="hasContatti()">
        <ul class="spalla-contatti col1">  
            <span class="indirizzo" v-if="selectedpoi.attributes.field_poi_indirizzo">{{ selectedpoi.attributes.field_poi_indirizzo }}</span><span v-if="selectedpoi.attributes.field_poi_indirizzo"><br></span>
            <span class="frazione" v-if="selectedpoi.attributes.field_poi_frazione">{{ selectedpoi.attributes.field_poi_frazione }}</span><span v-if="selectedpoi.attributes.field_poi_frazione"><br></span>
            <span class="comune" v-if="selectedpoi.attributes.field_poi_comune">{{ selectedpoi.attributes.field_poi_comune }}</span>
        </ul>
        <ul class="spalla-contatti col2">
          <li v-if="selectedpoi.attributes.field_poi_email" class="email">{{ selectedpoi.attributes.field_poi_email }}</li>
          <li v-if="selectedpoi.attributes.field_poi_mobile" class="tel">{{ selectedpoi.attributes.field_poi_mobile }}</li>
          <li v-if="selectedpoi.attributes.field_poi_tel" class="tel">{{ selectedpoi.attributes.field_poi_tel }}</li>
          <li v-if="selectedpoi.attributes.field_poi_facebook" class="fb"><a target="_blank" :href="selectedpoi.attributes.field_poi_facebook.uri">Facebook</a></li>
          <li v-if="selectedpoi.attributes.field_poi_instagram" class="in"><a target="_blank" :href="selectedpoi.attributes.field_poi_instagram.uri">Instagram</a></li>
        </ul>
      </div>
    </div>

    <div v-if="selectedpoi.attributes.field_poi_categoria == 'Pista ciclabile'" >
      <div class="poi-contatti poi-ciclabile-info" 
        v-if="selectedpoi.attributes.field_poi_intro"
        v-html="selectedpoi.attributes.field_poi_intro.value"></div>
      
      <VueSlickCarousel v-bind="carouselSettings" v-if="carimages.length>0">
        <!--<div v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index">
          <img :src="imgsrc(image)" alt="">
        </div>-->
        <Images v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index"
          :breakstyles="{'0': 'sm_image'}" 
          :imagedata="selectedpoi" fieldname="field_poi_immagini" 
          :resultindex="index" />
      </VueSlickCarousel>

      <div class="poi-contatti poi-ciclabile-info" 
        v-if="selectedpoi.attributes.field_poi_descizione"
        v-html="selectedpoi.attributes.field_poi_descizione.value"></div>
      
    </div>


 
    <div v-if="selectedpoi.attributes.field_poi_categoria == 'Punto d\'interesse'" class="int-point" >
      
<!-- 
      messo if a id su ponte di pieris. perchè immagini messe dentro wysiwyg, no carousel
      però serviva un immagine per la lista a sx -->
      <div v-if="selectedpoi.id != '186842ce-51eb-442a-8e20-a58c58ba9390'">
          <VueSlickCarousel v-bind="carouselSettings" v-if="carimages.length>0">
          <!--<div v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index">
            <img :src="imgsrc(image)" alt="">
          </div>-->
          <Images v-for="(image, index) in selectedpoi.field_poi_immagini" :key="'carousel'+index"
            :breakstyles="{'0': 'sm_image'}" 
            :imagedata="selectedpoi" fieldname="field_poi_immagini" 
            :resultindex="index" />
        </VueSlickCarousel>
      </div>
      
<!-- 
      REPLACE STRING 
      serve perchè ponte di pieris aveva immagini nel wysiwyg che dovevamo recuperare dal back -->

      <div class="poi-descrizione"
      v-if="selectedpoi.attributes.field_poi_descizione"
      v-html="replaceString(selectedpoi.attributes.field_poi_descizione.value)"></div>


      <div class="poi-label-contatti" v-if="hasContatti()">{{ this.$store.state.labelData[this.lang].contatti.contatti }}</div>
      <div class="poi-contatti" v-if="hasContatti()">
        <ul class="spalla-contatti col1">  
          <li class="indirizzo" v-if="selectedpoi.attributes.field_poi_indirizzo">{{ selectedpoi.attributes.field_poi_indirizzo }}</li>
          <li class="frazione" v-if="selectedpoi.attributes.field_poi_frazione">{{ selectedpoi.attributes.field_poi_frazione }}</li>
          <li class="comune" v-if="selectedpoi.attributes.field_poi_comune">{{ selectedpoi.attributes.field_poi_comune }}</li>
        </ul>
        <ul class="spalla-contatti col2">
          <li v-if="selectedpoi.attributes.field_poi_email" class="email">{{ selectedpoi.attributes.field_poi_email }}</li>
          <li v-if="selectedpoi.attributes.field_poi_mobile" class="tel">{{ selectedpoi.attributes.field_poi_mobile }}</li>
          <li v-if="selectedpoi.attributes.field_poi_tel" class="tel">{{ selectedpoi.attributes.field_poi_tel }}</li>
          <li v-if="selectedpoi.attributes.field_poi_facebook" class="fb">{{ selectedpoi.attributes.field_poi_facebook }}</li>
        </ul>
      </div>
    </div>


    <!--<div style="text-align: right; width: 100%;">
      <router-link class="btn" :to="`/poi/${selectedpoi.category}/${selectedpoi.id}`">{{ this.$store.state.labelData[this.lang].dettaglio_button }}</router-link>
    </div>-->
  </div>
  </div>
</template>

<script>
import LeftTopButton from "@/components/LeftTopButton"
import VueSlickCarousel from 'vue-slick-carousel'
import Images from '../components/Images.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: {
    LeftTopButton,
    VueSlickCarousel,
    Images
  },
  data() {
    return {
      carouselSettings: {
        dots: false,
        arrows: true,
        focusOnSelect: true,
        infinite: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5
      },
      carimages: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    }
  },
  props: {
    selectedpoi: Object
  },
  methods: {
    replaceString(htmlContent) {
      return htmlContent.replace(/src="\/sites\/default\/files\/inline-images\//g, 'src="https://cdn.scoprisancanziandisonzo.org/sites/default/files/inline-images/');
    },
    closePOI() {
      document.body.classList.remove("noscroll")
      this.$emit("closed-poi")
    },
    imgsrc(image) {
      return process.env.VUE_APP_ENDPOINT + image.attributes.uri.url
    },
    changeCarousel() {
      if(window.innerWidth >= 1360) {
        this.carouselSettings.slidesToShow = 2
        this.carouselSettings.dots = false
        this.carouselSettings.arrows = true
      } else if(window.innerWidth > 768 && window.innerWidth < 992) {
        this.carouselSettings.slidesToShow = 2
        this.carouselSettings.dots = false
        this.carouselSettings.arrows = true
      } else {
        this.carouselSettings.slidesToShow = 1
        this.carouselSettings.dots = true
        this.carouselSettings.arrows = false
      }
    },
    hasContatti() {
      var att = this.selectedpoi.attributes
      return att.field_poi_email || att.field_poi_facebook || att.field_poi_frazione || att.field_poi_indirizzo || att.field_poi_instagram || att.field_poi_mobile || att.field_poi_tel
    }
  },
  mounted() {
    //console.log(this.selectedpoi)

    if(window.innerWidth < 992)
      document.body.classList.add("noscroll")

    this.changeCarousel()
    window.addEventListener("resize", this.changeCarousel)
    setTimeout(() => {
      this.carimages = this.selectedpoi.field_poi_immagini    
    }, 100)
  },
  watch: {
    lang() {
      this.closePOI()
    },
    selectedpoi() {
      this.carimages = []
      setTimeout(() => {
        this.carimages = this.selectedpoi.field_poi_immagini    
      }, 100)
      
      if(window.innerWidth < 992)
        document.body.classList.add("noscroll")
    }
  }
};
</script>

<style lang="scss">
@import "../styles/variables.scss";

.poi-content .poi-contatti p.figcaption,
.poi-content .poi-descrizione p.figcaption {
  font-size: 14px;
    margin-top: -1rem;
    display: block;
}

  

.poi-contatti.poi-ciclabile-info span {
  color: #C3923F;
}

//SLIDER ARROWS AND DOTS RESPONSIVE STYLE
.slick-slider {
  margin: 0;
  transform: translateX(-16px);
  width: calc(100vw);
}
.slick-prev, .slick-next {
  width: unset;
  height: unset;
  z-index: 4;
}
.slick-prev::before { right: -55px; } 
.slick-next::before { right: 55px; } 
.slick-prev:before, .slick-next:before { 
  color: #fff !important;
  font-size: 46px !important;
  position: relative;
}
.slick-slide {
  width: 100%;
  img {
    pointer-events: none;
    margin: 0 auto;
    object-fit: cover;
    width: 100% !important;
    height: 60vh !important;
  }
}
.slick-dots {
  bottom: 1em;
  li, li.slick-active {
    button::before {
      color: #fff;
      font-size: 10px;
    }
  }
}

@media (min-width: 576px) {
  .slick-prev::before { right: -75px; }
  .slick-next::before { right: 75px; }
}

@media (min-width: 768px) {
  .slick-slider {
    margin: 0 auto;
    width: 95%;
    transform: none;
  }

  //STYLE FOR 1 SLIDE
  /*.slick-slide {
    img {
      height: 70vh !important;
    }
  }
  .slick-prev::before { right: -50px; }
  .slick-next::before { right: 50px; }*/

  //STYLE FOR 3 SLIDES
  .slick-slide {
    width: 33.33%;
    img {
      width: 95% !important;
      height: unset !important;
       
    }
  }
  .slick-prev:before, .slick-next:before { 
    color: $primaryColor !important;
    font-size: 32px !important;
  }
  .slick-prev, .slick-prev::before { left: -15px; }
  .slick-next, .slick-next::before { right: -15px; }
}

@media (min-width: 992px) {
  .slick-slider {
    margin: 0;
    width: calc(100% + 32px);
    transform: translateX(-16px);
  }
  .slick-slide {
    width: 100%;
    img {
      margin: 0 auto;
      object-fit: cover;
      width: 100% !important;
      height: 60vh !important;
    }
  }
}

@media (min-width: 1360px) {
  .slick-slider {
    margin: 0;
    width: 100%;
    transform: none;
  }
  .slick-slide {
    width: 33.33%;
    img {
      width: 95% !important;
      height: unset !important;
       
    }
  }
  .int-point .slick-slide img {
    height: 40vh !important;
    object-fit: contain;
    object-position: center center;
  }

  .slick-prev:before, .slick-next:before { 
    color: $primaryColor !important;
    font-size: 32px !important;
  }
  .slick-prev, .slick-prev::before { left: -17px; }
  .slick-next, .slick-next::before { right: -17px; }
}

//SPALLA RESPONSIVE STYLE
.poi-external {
  width: 100%;
  position: fixed;
  top: 0;
  height: calc(100vh);
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  pointer-events: none;
}

.poi-content {
  width: 100%;
  max-width: unset;
  max-height: calc(100vh);

  margin-right: 0;
  padding: calc(102px + 80px) 1em 1em 1em;
  
  background-color: #fff;
  background-image: url("/images/bg-poi.png");
  background-size: 80%;
  background-position-x: 400px;
  background-repeat: no-repeat;
  color: #000;  
  
  pointer-events: all;
  overflow-y: scroll;
  display: block;

  .poi-title {
    width: 100%;
    font-size: 26px;
    color: #003366;
    line-height: 1.3;
  }
  .poi-subtitle {
    font-size: 20px;
    color: $greenColor;
    padding-bottom: 1em;
  }
  .poi-text {
    line-height: 2;
    color: #5d5c56;
    span { font-weight: 700; }
  }
  
  .poi-label-contatti {
    margin-top: 0;
    font-family: "Martel";
    margin-bottom: .4em;
    font-weight: 600;
    color: $primaryColor;
  }
  
  .poi-contatti {
    margin-bottom: 1em;
    p > span { font-weight: 700; }
    p { margin: .5em 0; }

    &:not(.poi-ciclabile-info) {
      display: flex;
      flex-direction: column;
      justify-content: unset;

      ul {
        width: 100%;

        li {
          padding:  0 0 14px 2em;
          word-break: break-all;

          &::before {
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            top: 6px;
            position: absolute;
            left: 0;
          }

          &.tel::before { background-image: url(../assets/icon-outline-phone.svg); }
          &.fb::before { background-image: url(../assets/icon-outline-facebook.svg); }
          &.in::before { background-image: url(../assets/icon-outline-instagram.svg); }

          //&.tel + .tel::before { background-image: none; }
          &.indirizzo::before { background-image: url(../assets/location.svg); }
          &.clock::before { background-image: url(../assets/clock.svg); }
          &.email::before {
            width: 18px;            
            background-image: url(../assets/icon-outline-mail.svg); }
          &.website::before { background-image: url(../assets/website.svg); }
          &.ticket::before { background-image: url(../assets/ticket.svg); }
          &.calendar::before { background-image: url(../assets/calendar.svg); }

        }
      }
    }
  }
  .poi-ciclabile-info {
    strong {
      font-weight: 700;
      color: $primaryColor;
    }
  }
}

@media (min-width: 576px) {
  .poi-content {
    margin-right: 0;
    width: 100%;
    max-width: unset;
    padding: calc(102px + 80px) 1em 1em 1em;

    //.poi-title { font-size: 30px; }
    //.poi-subtitle { font-size: 24px; }
    .photo-carousel {
      display: none;
    }
    .poi-contatti {
      &:not(.poi-ciclabile-info) {
        flex-direction: row;
        ul {
          width: 50%;
        }
      }
      p, ul, li {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) {
  .poi-content {
    padding: calc(112px + 80px) 1em 1em 1em;
    .poi-title {
      font-size: 34px;
    }
    .poi-subtitle {
      font-size: 26px;
    }
  }
}

/*
@media (min-width: 992px) {
  .poi-external {
    position: absolute;
    top: $dashboardHeightDesktop;
    height: calc(100% -  (2 * $dashboardHeightDesktop));
  }
  .poi-content {
    width: 60%;
    margin-top: 0;
    padding-top: 90px;
    .poi-label-contatti {
      margin-top: 1em;
    }
  }
}
*/

.poi-descrizione img  {
  width: 100%;
}

@media (min-width: 1024px) {
  .poi-external {
    position: absolute;
    top: 0;
    height: 100%;
  }
  .poi-content {
    width: 45%;
    margin-top: 0;
    padding-top: 112px;
    .poi-label-contatti { margin-top: 1em; }
    .poi-title { font-size: 32px; }
    .poi-subtitle { font-size: 20px; }
    .poi-descrizione p, .poi-contatti p { font-size: 1rem; }
    .poi-contatti:not(.poi-ciclabile-info) {
      flex-direction: column;
      ul { width: 100%; }
    }
  }
}

@media (min-width: 1360px) {
  .poi-content {
    width: 40%;
    max-width: 60%;
    padding: 40px;
    .poi-title { width: 85%; }
    .poi-subtitle {width: 85%; }
    .poi-label-contatti { margin-top: 2rem; }

    .poi-contatti {
      color: #5d5c56;
      line-height: 2;
      font-size: 1rem;
      &:not(.poi-ciclabile-info) {
        margin-top: 1rem;
        margin-bottom: 1em;
        flex-direction: row;
        justify-content: space-between;
      }
      ul { width: 50%; }
    }
  }
}

.filter { filter: drop-shadow(-2px -1px 4px rgba(0, 0, 0, 0.089)); }
.spalla-contatti { margin-bottom: 1em; }

</style>
