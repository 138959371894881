<template>
    <div class="poi-list-container" :style="passstyle" v-if="this.poiData">

        <ul class="poi-attivita poi-list" v-if="selectedlayer == 'Azienda'">
            <div v-for="item of attivita" :key="item.id" 
                :id="titletoid(item.attributes.title)" 
                :poi-id="item.attributes.drupal_internal__nid">

                <li @click="openPoi(item.attributes.drupal_internal__nid)">
                    <images :imagedata="item" fieldname="field_poi_immagini"
                        :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                    <div class="title">
                        <span class="item-title">{{ item.attributes.title }} </span>
                        <span class="item-info">
                            {{item.attributes.field_poi_sottotitolo}}
                        </span>
                    </div>
                </li>
            </div>
        </ul>

        <ul class="poi-ciclabili poi-list" v-if="selectedlayer == 'Pista ciclabile'">
            <div v-for="item of ciclabili" :key="item.id" 
                :id="titletoid(item.attributes.title)" 
                :poi-id="item.attributes.drupal_internal__nid">

                <li @click="openPoi(item.attributes.drupal_internal__nid)">
                    <images :imagedata="item" fieldname="field_poi_immagini"
                        :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                    <div class="title">
                        <span class="item-title">{{ item.attributes.title }} </span>
                        <span class="item-info">{{ item.attributes.field_poi_sottotitolo }}</span>
                    </div>
                </li>
            </div>
        </ul>

        <ul class="poi-interessi poi-list" v-if="selectedlayer == 'Punto d\'interesse'">
            <div v-for="item of interessi" :key="item.id"
                :id="titletoid(item.attributes.title)" 
                :poi-id="item.attributes.drupal_internal__nid">
                
                <li @click="openPoi(item.attributes.drupal_internal__nid)">
                    <images :imagedata="item" fieldname="field_poi_immagini"
                        :breakstyles="{'0': 'sm_image', '576': 'md_image'}"></images>
                    <div class="title">
                        <span class="item-title">{{ item.attributes.title }} </span>
                        <span class="item-info"
                            v-if="item.attributes.field_poi_indirizzo || item.attributes.field_poi_comune">
                            {{ item.attributes.field_poi_indirizzo + ', ' + 
                                (item.attributes.field_poi_frazione ? (item.attributes.field_poi_frazione + ', ') : '') +
                                item.attributes.field_poi_comune 
                            }}
                        </span>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
    import Images from '../components/Images.vue'
    export default {
        name: 'poi-list',
        data() {
            return {}
        },
        components: {
            Images
        },
        props: {
            selectedlayer: String,
            passstyle: String
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            poiData() {
                return this.$store.state.poiData
            },
            attivita() {
                return this.poiData.filter(c => c.attributes.field_poi_categoria == 'Azienda')
            },
            ciclabili() {
                return this.poiData.filter(c => c.attributes.field_poi_categoria == 'Pista ciclabile')
            },
            interessi() {
                return this.poiData.filter(c => c.attributes.field_poi_categoria == 'Punto d\'interesse')
            }
        },
        methods: {
            openPoi(id) {
                this.$emit('clicked-poi', id)
            },
            titletoid(string) {
                var s = "" 
                s = string.replace(/\s/g, '')
                s = s.replace(/\./g, '')
                s = s.replace(/’/g, '')
                s = s.replace(/'/g, '')
                return s
            }
        }

    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .poi-list-container {
        width: 100%;
        height: calc(100% - 2 * $dashboardHeightMobile);
        margin: 0 auto;
        position: absolute;
        z-index: 20;
        background-color: #fff;
        display: var(--togglelista);
    }

    .poi-list {
        list-style: none;
        overflow-y: scroll;
        height: 100%;
        direction: rtl;
        scrollbar-width: none;

        //&::-webkit-scrollbar { display: none; }

        >div {
            &:hover {
                background: #f0f0f0;
            }

            direction: ltr;
        }

        li {
            &::before {
                display: none;
            }

            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: calc(100% - 30px);

            margin: 0 auto;
            padding: 1.2rem 0;
            border-bottom: rgba(#666, .2) 1px solid;
            cursor: pointer;

            ::v-deep picture {
                display: flex;
                align-items: center;
            }

            ::v-deep picture {
                border-radius: 50px;
                border: transparent 3px solid;
                overflow: hidden;
                width: 60px;
                height: 60px;
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                max-width: 60px;
                max-height: 60px;

                img {
                    object-fit: cover;
                    width: 100px;
                    height: 100px;
                }
            }

            .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 2em;
                font-size: .8em;

                .item-title {
                    line-height: 1.2em;
                }

                .item-info {
                    color: $greenColor;
                    font-size: .8em;
                }
            }

            &:hover {
                .title {

                    .item-info,
                    .item-title {
                        color: $primaryColor;
                    }
                }
            }
        }
    }

    .poi-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: .8em;
    }

    .poi-attivita {
        .poi-title {
            color: $attivitaColor;
        }

        li:hover {
            ::v-deep picture img {
                border-color: $attivitaColor;
            }
        }
    }

    .poi-ciclabili {
        .poi-title {
            color: $ciclabiliColor;
        }

        li:hover {
            ::v-deep picture img {
                border-color: $ciclabiliColor;
            }
        }
    }

    .poi-interessi {
        .poi-title {
            color: $interessiColor;
        }

        li:hover {
            ::v-deep picture img {
                border-color: $interessiColor;
            }
        }
    }

    @media (min-width: 768px) {
        .poi-list-container {
            height: calc(100% - 2 * $dashboardHeightDesktop);
        }
    }

    @media (min-width: 1024px) {
        .poi-list-container {
            height: calc(100% - $dashboardHeightDesktop);
            //max-width: 510px;
            max-width: 35%;
            display: block;
        }
        .poi-list li {
            max-width: 80%;
        }
    }

    @media (min-width: 1200px) {
        .poi-list {
            //height: calc(100% - 21px);
            li {
                ::v-deep picture {
                    width: 80px;
                    height: 80px;
                    min-width: 80px;
                    min-height: 80px;
                    max-width: 80px;
                    max-height: 80px;
                }

                .title {
                    .item-title {
                        font-size: 18px;
                    }
                    .item-info {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media (min-width: 1500px) {
        .poi-list-container {
            //max-width: 583px;

            .poi-list li {
                max-width: 100%;
                padding-right: 30px;
                padding-left: 30px;
            }
        }
    }
</style>