<template>
    <div class="map" ref="map">
        <div class="legenda">
            <p class="blue">{{ this.$store.state.labelData[this.lang].cosafare.piste_comunali }}</p>
            <p class="orange">{{ this.$store.state.labelData[this.lang].cosafare.piste_extra }}</p>
        </div>
    </div>
</template>

<script>
    // TODO Gestione di dataset da mapbox
    // https://studio.mapbox.com/datasets/
    // API https://docs.mapbox.com/mapbox-gl-js/api/

    import mapboxgl from 'mapbox-gl'
    mapboxgl.accessToken =
        'pk.eyJ1IjoiaW50ZXJmYXNlIiwiYSI6ImNqYW8zZGJkeTRjNXQzNHIxYmJpMWF6MnkifQ.TfWAUiqo6rDYVGid30Q6sA'
    import 'mapbox-gl/dist/mapbox-gl.css'

    export default {
        data() {
            return {
                map: null,
                mapPoiData: null,
                bounds: new mapboxgl.LngLatBounds(),
                optionsMap: {
                    zoom: null,
                    center: null,
                    bounds: null
                },
                featuresAttivita: [],
                featuresCiclabili: [],
                offsetx: 0
            };
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            },
            selectedInsightID() {
                return this.$store.state.selectedInsightID
            }
        },
        methods: {
            initMap() {
                this.map = new mapboxgl.Map({
                    container: this.$refs.map,
                    style: 'mapbox://styles/interfase/ckwqacgo73tpi14s9krqht2jh'
                })

                this.map.on('load', () => {
                    this.addLayer('Azienda', '/images/icona-attivita.png', '#BB5353')
                    this.addLayer('Pista ciclabile', '/images/icona-piste-ciclabili.png', '#445068')
                    this.addLayer('Punto d\'interesse', '/images/icona-monumenti.png', '#C3923F')

                    //this.optionsMap.zoom = this.map.getZoom()
                    this.map.setZoom(11.99)
                    this.optionsMap.zoom = 11.99

                    //this.optionsMap.center = this.map.getCenter()
                    this.map.setCenter([13.469, 45.779])
                    this.optionsMap.center = [13.469, 45.779]

                    this.optionsMap.bounds = this.map.getBounds()
                })

                const vm = this
                this.map.on('click', function (e) {
                    let point = vm.map.queryRenderedFeatures(e.point, {
                        layers: [
                            'Azienda-points', 'Azienda-labels',
                            'Pista ciclabile-points', 'Pista ciclabile-labels',
                            'Punto d\'interesse-points', 'Punto d\'interesse-labels'
                        ]
                    })[0]
                    point && vm.selectPoi(point)
                })
            },
            addLayer(category, icon, color) {
                const vm = this
                this.map.loadImage(icon, (error, image) => {
                    if (error)
                        throw error;

                    vm.map.addImage('custom_marker-' + category, image)

                    var rateo = 35 / image.width

                    vm.map.addSource(category, {
                        type: 'geojson',
                        data: process.env.VUE_APP_ENDPOINT + '/mapdata',
                        cluster: true,
                        clusterMaxZoom: 0,
                        clusterRadius: 0
                    })

                    vm.map.addLayer({
                        'id': category + '-points',
                        'type': 'symbol',
                        'source': category,
                        'filter': ['==', 'category', category],
                        'layout': {
                            'icon-image': 'custom_marker-' + category,
                            'icon-size': rateo,
                            'icon-allow-overlap': true
                        }
                    })

                    vm.map.addLayer({
                        'id': category + '-labels',
                        'type': 'symbol',
                        'source': category,
                        'filter': ['==', 'category', category],
                        'paint': {
                            'text-color': color
                        },
                        'minzoom': 14,
                        'layout': {
                            'text-field': ['get', 'title'],
                            'text-font': ['Open Sans Regular'],
                            'text-offset': [1.5, 0],
                            'text-anchor': 'left',
                            'text-size': 14,
                            'text-allow-overlap': true
                        }
                    })
                })
            },
            resetMap() {
                this.map.fitBounds(this.optionsMap.bounds, {
                    maxZoom: this.optionsMap.zoom
                })
                /*this.featuresAttivita.forEach((feature)=>{
                    this.map.setFeatureState({source: 'composite', sourceLayer:'sancanziano_attivita' , id: feature.id}, { transparent: false});
                })
                this.featuresCiclabili.forEach((feature)=>{
                    this.map.setFeatureState({source: 'composite', sourceLayer:'sancanziano_ciclabili_point' , id: feature.id}, { transparent: false});
                })*/
            },
            selectPoi(point) {
                var vm = this
                const zoom = point.properties.zoom ? point.properties.zoom : 15
                this.map.easeTo({
                    center: point.geometry.coordinates,
                    zoom,
                    offset: [vm.offsetx, 0]
                });

                this.map.queryRenderedFeatures({
                    layers: ['Azienda-points', 'Pista ciclabile-points', 'Punto d\'interesse-points']
                }).forEach((feature, i) => {
                    this.map.setFeatureState({
                        source: feature.source,
                        sourceLayer: feature.sourceLayer,
                        id: i
                    }, {
                        transparent: true
                    });
                })

                this.map.setFeatureState({
                    source: point.source,
                    sourceLayer: point.sourceLayer,
                    id: ''
                }, {
                    transparent: false
                });

                this.$emit('clicked-poi', point.properties.nid)
            },
            changeOffset() {
                if (window.innerWidth < 768)
                    this.offsetx = 0
                if (window.innerWidth >= 768)
                    this.offsetx = -300
                if (window.innerWidth >= 1200)
                    this.offsetx = -335
                if (window.innerWidth >= 1300)
                    this.offsetx = -400
                if (window.innerWidth > 1500)
                    this.offsetx = -450
            }
        },
        async mounted() {
            this.initMap()
            await fetch(process.env.VUE_APP_ENDPOINT +
                    ((this.lang == this.defaultLang) ? '' : ('/' + this.lang)) +
                    '/mapdata')
                .then(res => res.json())
                .then(json => this.mapPoiData = json)

            this.changeOffset()
            window.addEventListener("resize", this.changeOffset)
        },
        watch: {
            selectedInsightID() {
                if (this.selectedInsightID != null) {

                    var point = this.mapPoiData.features.find(p => p.properties.nid == this.selectedInsightID)
                    //console.log(point.properties.title)

                    var vm = this
                    const zoom = point.properties.zoom ? point.properties.zoom : 15
                    this.map.easeTo({
                        center: point.geometry.coordinates,
                        zoom,
                        offset: [vm.offsetx, 0]
                    })
                } else {
                    this.resetMap()
                }
            },
            lang() {
                this.resetMap()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";

    .legenda {
        position: absolute;
        z-index: 2;
        background: #ffffffbf;
        left: 15px;
        bottom: 15px;
        display: flex;
        padding: 7px 0px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 20px 0px #00000015;
        width: calc( 100% - 30px);

        p {
            margin: 0 10px;
            max-width: 180px;
            line-height: 15px;
            font-size: 10px;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &:before {
                content: "";
                display: block;
                position: relative;
                width: 35px;
                height: 35px;
                max-width: 35px;
                max-height: 35px;
                min-width: 35px;
                margin-right: 8px;
                background-size: cover;
            }
        }
        
        .orange {
            color: #e48860;
             &:before {
                background-image: url("../assets/legenda-piste-orange.png");
            }
        }
        
        .blue {
            color: #5a6479;
             &:before {
                background-image: url("../assets/legenda-piste-blue.png");
            }
        }

    }

    .map {
        top: 0;
        left: 0;
        height: 70vh;
        max-width: 100%;
        margin: 0 auto;
    }

    @media (min-width: 480px) {
        .legenda {
            width: auto;
            padding: 16px 10px;
            p {
                line-height: 20px;
                font-size: 14px;
                &:before {
                    width: 45px;
                    height: 45px;
                    max-width: 45px;
                    max-height: 45px;
                    min-width: 45px;
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .legenda p{
            max-width: 200px;
        }
   
        .map {
            top: -$dashboardHeightDesktop;
            margin: 0;
            margin-left: auto;
            height: 100%;
            max-width: 65%;
        }
    }

    .mapboxgl-marker {
        width: 80px;
    }
    
</style>