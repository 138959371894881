<template>
  <div class="wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'wrapper'
}
</script>

<style lang="scss" scoped>
.wrapper {
  z-index: 0;
  position: relative;
  width: 100%;
  //height: 70vh;
  height: fit-content;
}

@media (min-width: 1024px) {
  .wrapper {
    height: calc(100vh - 112px);
    margin-top: 5vh;
  }
}

</style>
