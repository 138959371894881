<template>
  <div class="main-container cosa-fare" v-if="this.pageData">

    <header class="header-intro-big">
      <img src="../assets/home-person.png" alt="">
      <!--<img class="image-desktop" src="../assets/cosa-fare-bg-intro.jpg" alt="">-->
        <div class="container" v-if="this.bannerData && this.bannerData.attributes">
        <h1 v-html="this.bannerData.attributes.field_ui_text_titolo.value">
        </h1>
        <p v-html="this.bannerData.attributes.field_text_with_summary.value">
        </p>
      </div>
    </header>

    <div class="main-content">
      <div class="container" v-if="this.testiPagina[0]">
        <h2 v-html="this.testiPagina[0].attributes.field_ui_text_titolo.value"></h2>
        <p v-html="this.testiPagina[0].attributes.field_text_with_summary.value"></p>
      </div>
      <br>
      <div class="container" v-if="this.testiPagina[1]">
        <h2 v-html="this.testiPagina[1].attributes.field_ui_text_titolo.value"></h2>
        <p v-html="this.testiPagina[1].attributes.field_text_with_summary.summary">
        </p>
      </div>

      <Wrapper id="map-container">
        <DashboardMap @select-layer="selectLayer"/>

        <PoiList :selectedlayer="selectedLayer" @clicked-poi="selectPOI" :passstyle="toggleLista"/>

        <Map @clicked-poi="selectPOI" :geojson="geojson"/>

        <!--<transition name="fade" appear>
            <div class="overlay-gesture" @click="clickOnGesture()" v-show="showGestures">
                <img src="/images/overlay-gesture-map.png">
            </div>
        </transition>-->

        <SpallaMappa @closed-poi="selectPOI(null)" v-if="selectedInsightID" :selectedpoi="selectedPoi" />

        <div class="filters dashboard-map list-toggler">
          <div class="layers">
            <button class="button toggler" @click="hideLista = !hideLista">
              <div class="content">
                <span>{{ !this.hideLista ? 
                  this.$store.state.labelData[this.lang].cosafare.torna_button 
                  : this.$store.state.labelData[this.lang].cosafare.vedilista_button }}</span>
              </div>
            </button>
          </div>
        </div>
          
      </Wrapper>

    </div>
  </div>
</template>

<script>
import Map from '@/components/Map'
import DashboardMap from '@/components/DashboardMap'
import Wrapper from '@/components/Wrapper'
import PoiList from '@/components/PoiList'
import SpallaMappa from '@/components/SpallaMappa'
import { fetchNodes, fetchSinglePage, getTranslation } from '../libs/drupalClient'

export default {
  name: 'cosa-fare',
  components: {
    Map,
    DashboardMap,
    PoiList,
    Wrapper,
    SpallaMappa,
  },
  data() {
    return {
      selectedLayer: 'Azienda',
      hideLista: false,
      geojson: {},
      currentPageID: "22a0e792-be9f-4862-b385-b663f544e0c7",
      pageData: null
      //showGestures: true
    }
  },
  metaInfo() {
    return {
      title: this.pagemetatitle,
      meta: [
        {
          property: 'og:title',
          content: this.pagemetatitle
        }
      ]
    }
  },
  computed: {
    pagemetatitle() {
      if(this.lang == 'it') return 'Cosa Fare'
      else if(this.lang=='de') return 'Was Ist Zu Tun'
      else return 'What To Do'
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    poiData() {
      return this.$store.state.poiData
    },
    selectedInsightID() {
      return this.$store.state.selectedInsightID
    },
    selectedPoi() {
      return this.poiData.filter(p => p.attributes.drupal_internal__nid == this.selectedInsightID)[0]
    },
    toggleLista() {
      return '--togglelista: ' + (this.hideLista ? 'none' : 'block') + ';'
    },
    bannerData() {
      return this.pageData.field_ref_ui_text.filter(f => f.attributes.field_tipo=='Banner')[0]
    },
    testiPagina() {
      return this.pageData.field_ref_ui_text.filter(f => f.attributes.field_tipo=='Testo pagina')
    },
    hash() {
      return this.$route.hash
    }
  },
  methods: {
    selectPOI(id) {
      //console.log('cosafare selected', id)
      //this.$router.push({ hash: '' })
      this.hideLista = true
      this.$store.commit("SET_SELECTED_INSIGHT", id)
    },
    openPOI(id, cat) {
      this.$store.commit("SET_SELECTED_INSIGHT", id)
      this.$router.push("/poi/" + cat + "/" + id)
    },
    selectLayer(name) {
      this.selectedLayer = name
    },
    async fetchPois() {
      await fetchNodes('poi', {
        include: ['field_poi_immagini']
      }, this.lang)
      .then(res => {
        var ordered = []

        var feudi = res.filter(r => r.attributes.drupal_internal__nid == 126)
        if(feudi.length>0) ordered.push(feudi[0])

        var officinali = res.filter(r => r.attributes.drupal_internal__nid == 33)
        if(officinali.length>0) ordered.push(officinali[0])

        var ostaria = res.filter(r => r.attributes.drupal_internal__nid == 32)
        if(ostaria.length>0) ordered.push(ostaria[0])

        res.forEach(r => {
          if(r.attributes.drupal_internal__nid!=126
            && r.attributes.drupal_internal__nid!=33
            && r.attributes.drupal_internal__nid!=32)
            ordered.push(r)
        })

        this.geojson = { 'data': ordered }
        this.$store.commit('SET_POIDATA', ordered)
      })
    }
  },
  async mounted() {
    await fetchSinglePage(this.currentPageID, this.lang)
    .then(res => this.pageData = res)

    await this.fetchPois()

    if(this.hash) {
      var qs = document.querySelector(this.hash).getAttribute('poi-id')
      this.selectPOI(qs)

      await setTimeout(() => {
        console.log(this.selectedInsightID)

        window.scrollBy({
          top: document.querySelector('#map-container').getBoundingClientRect().top - 110,
          behavior: 'smooth'
        })
      }, 200)
    }
  },
  watch: {
    async lang() {
      await getTranslation(this.$route.name, this.lang)
      .then(res => this.$router.push({path: res}))

      await fetchSinglePage(this.currentPageID, this.lang)
      .then(res => this.pageData = res)

      await this.fetchPois()
    },
    /* async hash() {
      var qs = document.querySelector(this.hash).getAttribute('poi-id')
      this.selectPOI(qs)

      await setTimeout(() => {
        
        window.scrollBy({
          top: document.querySelector('#map-container').getBoundingClientRect().top - 110,
          behavior: 'smooth'
        })
      }, 300)
    } */
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

img { width: 100%; }
.main-content .container h2 { word-break: break-all; }

@media (min-width: 576px) {
  .main-content .container h2 { word-break: normal; }
}

@media (min-width: 1024px) {
  .list-toggler { display: none; }

  .main-content {
    position: relative;
    margin-bottom: 0;

    &:before {
      content: "";
      top: -44rem;
      right: 0;
      position: absolute;
      background: url("../assets/cosa-fare-deco-1.png");
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: right top;
      z-index: -3;
      max-width: 550px;
      background-size: contain;
    }
            
    .container { 
      max-width: 960px; 
      h2, p { width: 85%; }
    }
  }
}

@media (min-width: 1280px) {
  .main-content .container h2, p { width: 100%; }
}
</style>