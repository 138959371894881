<template>
<!--<transition appear name="slide-left">-->
  <div class="filters dashboard-map">
    <div class="layers">

      <div class="button" 
        @click="selectLayer('Azienda')" 
        :class="{'attivita': true, select: select['Azienda'], 'de': this.lang=='de'}">
        <div class="content">
          <img src="/images/icon-dash-attivita.svg"><span>{{ this.$store.state.labelData[this.lang].cosafare.attivita }}</span>
          <!--<span class="smallText">aderenti al progetto</span>-->
        </div>
      </div>

      <div class="button" 
        @click="selectLayer('Pista ciclabile')" 
        :class="{'ciclabili-point': true, select: select['Pista ciclabile'], 'de': this.lang=='de'}">
        <div class="content">
          <img src="/images/icon-dash-ciclabili.svg"><span>{{ this.$store.state.labelData[this.lang].cosafare.ciclabili }}</span>
        </div>
      </div>

      <div class="button" 
        @click="selectLayer('Punto d\'interesse')" 
        :class="{'interessi-point': true, select: select['Punto d\'interesse'], 'de': this.lang=='de'}">
        <div class="content">
          <img src="/images/icon-dash-monumenti.svg"><span>{{ this.$store.state.labelData[this.lang].cosafare.interessi }}</span>
        </div>
      </div>

    </div>
    
  </div>
<!--</transition>-->
</template>

<script>

export default {
  name: 'dashboard-map',
  data() {
    return {
      select: {
        'Azienda': true,
        'Pista ciclabile': false,
        'Punto d\'interesse': false
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    }
  },
  methods: {
    selectLayer(layer) {
      this.select['Azienda'] = false
      this.select['Pista ciclabile'] = false
      this.select['Punto d\'interesse'] = false
      this.select[layer] = true
      this.$emit('select-layer', layer)
    }
  }
};
</script>

<style lang="scss" scoped>

.button {
  cursor: pointer;
  &:hover {
    .content {
      opacity: 1;
    }
  }
}

@media (min-width: 1024px) {
  .button.de {
    span { font-size: 12px; }
  }
}
</style>
